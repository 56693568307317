body {
  color: #440099!important;
}

.scrollview {
  max-height: calc(75vh);
  overflow-y: auto;
}

/* fix to prevent safari from overlapping contents */
.scrollview>* {
  flex: 0 0 75%;
}

.btn.btn-primary {
  background-color: #2AD2C9;
  border-color: #2AD2C9;
}

.btn.btn-primary:hover {
  background-color: #440099;
  border-color: #440099;
}

.checkboxes-container {
  padding: 5px;
  border: 2px solid #ccc; 
  width: 500px; 
  height: 150px; 
  overflow-y: scroll; 
}

.container-eligibility-list {
  padding: 5px;
  border: 2px solid #ccc; 
}

.container-eligibility {
  padding: 5px;
  border: 2px solid #ccc; 
}

.container-route {
  padding: 10px;
  border: 2px solid #ccc; 
}

.list-group-item.active {
  background-color: #f2e7ff!important;
  color: #440099!important;
}

.dealtypes .input-group-text {
  justify-content: center;
}

.discountUsage .input-group-text {
  background-color: white !important;
  border: 0 !important;
}

div.dtPosition .rdtPicker .rdtDays {
  margin-top: -308px !important;
  background-color: whitesmoke;
}

div.dtPosition .rdtPicker .rdtTime {
  background-color: whitesmoke;
}

.green {
  color: #58DB83;
}

.red {
  color: #EC536C;
}